
import { defineComponent, ref, onMounted } from "vue";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import AJLoader from "@/components/AjaxLoader.vue";
// import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { IGetAgentForViewDto } from "@/shared/service-proxies/service-proxies";
import { AgentsFilter } from "@/models";
import useAgents from "@/composables/useAgents";
import DeleteItemService from "@/core/services/DeleteItemService";
import { useStore } from "vuex";

export default defineComponent({
  name: "agents",
  components: {
    Datatable,
    AJLoader,
  },
  setup() {
    const checkedCustomers = ref([]);
    const tableHeader = ref([
      {
        name: "Actions",
        key: "actions",
      },
      {
        name: "First name",
        key: "firstName",
        sortingField: "agent.firstName",
        sortable: true,
      },
      {
        name: "Last name",
        key: "lastName",
        sortingField: "agent.lastName",
        sortable: true,
      },
      {
        name: "Email",
        key: "email",
        sortingField: "agent.email",
        sortable: true,
      },
      {
        name: "Phone number",
        key: "phoneNumber",
        sortingField: "agent.phoneNumber",
        sortable: true,
      },
      {
        name: "Territory name",
        key: "territoryName",
        sortingField: "territoryName",
        sortable: true,
      },
    ]);

    const loaded = ref(false);
    const showAdvanceFilter = ref<boolean>(false);

    const { getAgents, agents, deleteAgent } = useAgents();
    let tableData = ref<Array<IGetAgentForViewDto>>([]);
    const initAgents = ref<Array<IGetAgentForViewDto>>([]);
    const breadcrumbs = {
      title: "Agents",
      breadcrumbs: ["Agents"],
    };
    const store = useStore();
    const config = store.getters.getConfigurations;
    const permissions = config.result.auth.grantedPermissions;

    const defaultAgentFilter = ref({
      filter: null,
      firstnameFilter: null,
      lastnameFilter: null,
      sorting: null,
      skipCount: undefined,
      maxResultCount: 1000,
      cancelToken: null,
    } as unknown as AgentsFilter);

    onMounted(async () => {
      // setCurrentPageBreadcrumbs("Agents", ["Agents"]);

      await getData();

      initAgents.value.splice(0, tableData.value.length, ...tableData.value);
    });

    const getData = async () => {
      loaded.value = false;

      await getAgents(defaultAgentFilter.value as unknown as AgentsFilter);

      tableData.value = agents.value;

      loaded.value = true;
    };

    const deleteHandler = async (id: string) => {
      const res = await DeleteItemService.delete(id as string, deleteAgent);

      if (res) {
        for (let i = 0; i < tableData.value.length; i++) {
          if (tableData.value[i].agent.id === id) {
            tableData.value.splice(i, 1);
          }
        }
      }
    };

    const searchFunc = async () => {
      await getData();
    };

    return {
      tableData,
      tableHeader,
      checkedCustomers,
      loaded,
      defaultAgentFilter,
      showAdvanceFilter,
      searchFunc,
      deleteHandler,
      breadcrumbs,
      permissions,
    };
  },
});
